.App {
  background: var(--grad, linear-gradient(135deg, #1e5048 0%, #5dd5d5 77.06%));
  min-height: 100vh;
}
.globalButton {
  border: 1px solid #ff5c34;
  background-image: -webkit-linear-gradient(
    30deg,
    #ff5c34 50%,
    transparent 50%
  );
  background-image: linear-gradient(30deg, #ff5c34 50%, transparent 50%);
  background-size: 300%;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.globalButton:hover {
  background-position: 100%;
}

.globalButton2 {
  border: 1px solid white;
  background-image: -webkit-linear-gradient(
    30deg,
    #ff5c34 50%,
    transparent 50%
  );
  background-image: linear-gradient(30deg, #ff5c34 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 100%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  p {
    color: var(--White, #fff);
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 700;
  }
}
.globalButton2:hover {
  background-position: 0%;
}

//

input[type="file"] {
  display: none;
}

label {
  cursor: pointer;
}

.svgWrapper {
  svg path {
    stroke: #007074;
  }
}

// drop down
// Manage promotions
.ant-select {
  &.manage-promotion {
    width: 100%;
    height: 64px;
    .ant-select-selector {
      padding: 20px 12px;
      border-radius: 12px;
      * {
        color: var(--Black, #000);
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        border-color: #007074 !important;
      }
    }
    .ant-select-arrow {
      // margin-top: 0px;
    }
  }
}

// toasts
.ant-notification-notice-wrapper {
  border-radius: 0 !important;
  background: transparent;
  box-shadow: none;
}
.ant-notification-notice {
  overflow: auto !important;
  max-width: 346px !important;
  position: relative;
  border-radius: 0;
  padding: 12px !important;
  width: 100%;
  &::before {
    content: "";
    height: 100%;
    width: 5px;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    background: #007074;
  }
  &.custom-toast-success {
    background: var(--light-surface-secondary, #f8fafc);
    .ant-notification-notice-with-icon {
      position: relative;
    }
    .anticon-check-circle.ant-notification-notice-icon-success {
      color: #007074;
    }
    .anticon-check-circle,
    .anticon-info-circle,
    .anticon-exclamation-circle,
    .anticon-close-circle {
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto 0;
    }
    * {
      font-family: "Public Sans";
    }
    .ant-notification-notice-message {
      color: #007074;
      font-family: "Public Sans";
      font-size: 18px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: -5px !important;
    }
    .ant-notification-notice-description {
      color: var(--Black, #000);
      font-family: "Public Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      opacity: 0.4;
    }
    &.custom-toast-info {
      .anticon-info-circle,
      .ant-notification-notice-message {
        color: #329bfc;
      }
      &::before {
        background: #329bfc;
      }
    }
    &.custom-toast-warning {
      .anticon-info-circle,
      .ant-notification-notice-message {
        color: #fed07a;
      }
      &::before {
        background: #fed07a;
      }
    }
    &.custom-toast-error {
      .anticon-info-circle,
      .ant-notification-notice-message {
        color: #f56642;
      }
      &::before {
        background: #f56642;
      }
    }
  }
}

// scroll bar
/* For Webkit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #007073;
  border-radius: 6px; /* Adjust the border-radius as needed */
}

/* For Firefox */
/* You can use scrollbar-color directly in the root of your CSS file */
/* Or you can apply it to the container where you want to style the scrollbar */
/* In this example, we use it for the root */
:root {
  scrollbar-color: #007073 white; /* Adjust the color and track color as needed */
}

/* For Firefox, you can also use scrollbar-width to adjust the width */
:root {
  scrollbar-width: thin; /* thin, auto, or none */
}

// Pagination

.ant-pagination {
  .ant-pagination-options {
    display: none;
  }
}

// alerts-notifications
// .alerts-notifications {
//   padding: 0;
// }

// datepicker-custom
.ant-picker {
  &.datepicker-custom {
    width: 100%;
    border-radius: 8px;
    .ant-picker-input {
      height: 46px;
      input {
        font-family: "Public Sans";
        font-size: 16px;
        color: #4c5363;
        &::placeholder {
          color: #667085;
        }
      }
      .ant-picker-suffix {
        color: #969696;
      }
    }
  }
}

// custom-checkbox
.custom-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #007073 !important;
      border-color: #007073 !important;
    }
    &:hover {
      .ant-checkbox-inner {
        background-color: #007073 !important;
        border-color: #007073 !important;
      }
    }
  }
  &:hover {
    .ant-checkbox-inner {
      background-color: #007073;
      border-color: #007073 !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  :where(.css-dev-only-do-not-override-1qhpsh8).ant-picker-calendar
    .ant-picker-calendar-header {
    display: flex !important;
  }
}

// .slick-list{
//   padding-left: 25% !important;
//   padding-right: 25% !important;
// }

.right-menu {
  max-height: calc(100vh - 90px);
  overflow: scroll;
}

// media query

@media screen and (max-width: 1023px) {
  .ant-select {
    &.manage-promotion {
      height: 56px;
    }
  }
}
